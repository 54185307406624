
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-py-60"> 
                            <div class="row"> 
                                <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <a href="javascript:void(0)" class="logo-footer">
                                        <img src="assets/images/logo-light.png" height="22" alt="">
                                    </a>
                                    <p class="mt-4">Start working with Bleenco to empower your sensor-based 
                                        solutions with AI capabilities.</p>
                                    <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                        <li class="list-inline-item ms-1"><a href="https://www.linkedin.com/company/bleenco" class="rounded" target="_blank">
                                                <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                            </a></li>
                                    </ul>
                                    <!--end icon--> 
                                </div>
                                <!--end col-->

                                <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Company</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li><a routerLink="javascript:void(0)" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i>
                                                About us </a><span class="badge rounded-pill bg-info me-1">SOON</span></li>
                                        <li><a routerLink="/newsroom" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i>
                                                Newsroom</a></li>
                                        <li><a routerLink="javascript:void(0)" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i>
                                                Careers </a><span class="badge rounded-pill bg-info me-1">SOON</span></li>
                                        <li><a routerLink="javascript:void(0)" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i>
                                                Blog </a><span class="badge rounded-pill bg-info me-1">SOON</span></li>
                                        <li><a routerLink="/contact" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i>
                                                Contact</a></li>
                                    </ul>
                                </div>
                                <!--end col-->

                                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Useful Links</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li><a routerLink="/impressum" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i>
                                                Impressum</a></li>
                                        <li><a routerLink="/privacy" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i>
                                                Privacy Policy</a></li>
                                    </ul>
                                </div>
                                <!--end col-->

                                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Newsletter</h5>
                                    <p class="mt-4">Sign up and receive the latest tips via email.</p>
                                    <form [formGroup]="newsForm" (ngSubmit)="onSubmit()">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="foot-subscribe foot-white mb-3">
                                                    <label class="form-label">Write your email <span
                                                            class="text-danger">*</span></label>
                                                    <div class="form-icon position-relative">
                                                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                        <input type="email" name="email" id="emailsubscribe"
                                                            class="form-control ps-5 rounded"
                                                            placeholder="Your email : " required formControlName="email"
                                                            [ngClass]="{ 'is-invalid': submitted && form['email'].errors }">
                                                        <div *ngIf="submitted && form['email'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="form['email'].errors">Please Enter Email.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                            <div class="col-lg-12">
                                                <div class="d-grid">
                                                    <input type="submit" id="submitsubscribe" name="send"
                                                        class="btn btn-soft-primary" value="Subscribe">
                                                </div>
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end row-->
                                    </form>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end container-->
            <!--end footer-->
            <div class="footer-py-30 footer-bar">
                <div class="container text-center">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <div class="text-sm-start">
                                <p class="mb-0">©
                                    2017-{{year}}
                                    Bleenco GmbH.
                                </p>
                            </div>
                        </div>
                        <!--end col-->

                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
            </div>
            <!--end footer--> 
        </footer>
    </ng-template>

    <div *ngIf="footerVariant;else execute_block">
    </div>
