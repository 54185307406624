import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';

interface IContact {
  name: string;
  email: string;
  subject?: string;
  comments: string;
}

@Component({
  selector: 'app-page-contact',
  templateUrl: './page-contact.component.html',
  styleUrls: ['./page-contact.component.css']
})

/**
 * Page Contact-Two Component
 */
export class PageContactComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;

  form: UntypedFormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.email,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
    ],
    subject: [''],
    comments: ['', Validators.required]
  });

  error: string | undefined;
  mailSent = false;
  isLoading = false;
  submitted = false;

  constructor(private http: HttpClient, private fb: UntypedFormBuilder) { }

  ngOnInit(): void { }


  onSubmit(): void {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    this.error = undefined;
    this.mailSent = false;
    this.isLoading = true;

    const data = this.form.value as IContact;

    this.http.post(`/api/v1/contact`, data)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (resp) => {
          console.log(resp);
          this.mailSent = true;
          this.form.reset();
        },
        error: (err) => {
          console.error(err);
          this.error = err;
        }
      });
  }
}
