import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/**
 * Index Component
 */
export class IndexComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;


  /***
   * Masonry Option Function
   */
  public myOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  /**
   * Portfolio Masonry Two Data
   */
  filterredImages: { image: string; title: string; text: string; category: string; }[];
  galleryFilter = 'all';

  private _album = [];

  list = [{
    image: 'assets/images/casestudies/uc-01.jpg',
    title: 'Automated checking of safety equipment and signalling',
    text: 'Process Automation',
    category: 'process'
  },
  {
    image: 'assets/images/casestudies/uc-03.jpg',
    title: 'Predicting properties and disturbances in an energy grid',
    text: 'Product Enhancement',
    category: 'product'
  },
  {
    image: 'assets/images/casestudies/uc-04.jpg',
    title: 'Monitoring forklift driver behavior and signalling',
    text: 'Process Automation',
    category: 'process'
  },
  {
    image: 'assets/images/casestudies/uc-02.jpg',
    title: 'A vehicle assistant system for visual teaching with previously unseen objects',
    text: 'Product Enhancement',
    category: 'product'
  }
  ];


  ngOnInit(): void {
    this.filterredImages = this.list;
  }

  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
    }
  }


  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-signal-alt-3 h1 text-primary",
      title: "Signals Analysis",
      description: "Our algorithms and models are designed to process real-time sensor signals."
    },
    {
      icon: "uil uil-compress-arrows h1 text-primary",
      title: "Sensor Fusion",
      description: "Our software supports the automated synchronization and fusion of various sensors."
    },
    {
      icon: "uil uil-toggle-off h1 text-primary",
      title: "Offline Networks",
      description: "We enable AI solutions built with Bleenco to operate in offline networks."
    }
  ];

  constructor(private modalService: NgbModal) { }

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
  };

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  openModal(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
