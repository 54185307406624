<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100 d-print-none" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title">Impressum</h4>
          <ul class="list-unstyled mt-4 mb-0">
            <li class="list-inline-item h6 date text-muted">
              <span class="text-dark">Last Revised :</span> 7th Nov, 2023
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Impressum</li>
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Legal Disclosure</h5>
            <p class="text-muted">
              INFORMATION IN ACCORDANCE WITH SECTION 5 TMG
            </p>
            <p class="text-muted">
              Bleenco GmbH, Dachauer Straße 44, 80335 München
            </p>

            <h6 class="card-title">Represented By</h6>
            <p class="text-muted">Irman Abdić</p>

            <h6 class="card-title">Contact Information</h6>

            <div class="text-muted">
              E-Mail: hello&#64;bleenco.com <br />
              Internet address: www.bleenco.com
            </div>

            <h6 class="card-title">Register Entry</h6>

            <div class="text-muted">
              Entry in: Handelsregister <br />
              Register Number: 232814 <br />
              Register Court: München <br />
            </div>

            <h6 class="card-title">VAT Number</h6>
            <p class="text-muted">
              VAT identification number in accordance with Section 27 a of the
              German VAT act DE312054975.
            </p>

            <h6 class="card-title">Disclamer</h6>

            <p class="text-muted">
              <b>Accountability for content</b> <br />
              The contents of our pages have been created with the utmost care.
              However, we cannot guarantee the contents’ accuracy, completeness
              or topicality. According to statutory provisions, we are
              furthermore responsible for our own content on these web pages. In
              this matter, please note that we are not obliged to monitor the
              transmitted or saved information of third parties, or investigate
              circumstances pointing to illegal activity. Our obligations to
              remove or block the use of information under generally applicable
              laws remain unaffected by this as per §§ 8 to 10 of the Telemedia
              Act (TMG).
            </p>

            <p class="text-muted">
              <b>Accountability for links</b> <br />
              Responsibility for the content of external links (to web pages of
              third parties) lies solely with the operators of the linked pages.
              No violations were evident to us at the time of linking. Should
              any legal infringement become known to us, we will remove the
              respective link immediately.
            </p>

            <p class="text-muted">
              <b>Copyright</b> <br />
              Our web pages and their contents are subject to German copyright
              law. Unless expressly permitted by law, every form of utilizing,
              reproducing or processing works subject to copyright protection on
              our web pages requires the prior consent of the respective owner
              of the rights. Individual reproductions of a work are only allowed
              for private use. The materials from these pages are copyrighted
              and any unauthorized use may violate copyright laws.
            </p>

            <a href="print()" class="btn btn-soft-primary d-print-none">
              Print
            </a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
