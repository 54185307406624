import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  get window(): Window | null {
    return this.doc.defaultView;
  }

  constructor(private router: Router, @Inject(DOCUMENT) private doc: Document) {
    /**
     * Unicons icon refreshed on route change.
     */
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // window['Unicons']();
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }

      this.window?.scrollTo?.(0, 0)
    });
  }
}
