<!-- Hero Start -->
<section
  class="bg-half-170 border-bottom d-table w-100"
  id="home"
  style="padding-bottom: 0px"
>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading mt-4">
          <div class="alert alert-white alert-pills shadow" role="alert">
            <span class="badge rounded-pill bg-danger me-1">NEW</span>
            <span class="content"
              ><span class="text-primary">Bleenco Inside</span> — Develop AI
              solutions that are 100% yours</span
            >
          </div>
          <h1 class="heading mb-3">
            Empowering sensor-based solutions with AI capabilities
          </h1>
          <p class="para-desc text-muted">
            Make your critical infrastructure smarter. Enhance your sensor-based
            products and processes with real-time AI capabilities.
          </p>
          <div class="mt-4">
            <a routerLink="/contact" class="btn btn-outline-primary rounded">
              <i class="uil uil-rocket"></i>
              Contact Sales
            </a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img
            src="assets/images/bleenco-inside.jpg"
            class="rounded img-fluid mx-auto d-block"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Partners start -->
  <div class="py-4 bg-light" style="margin: 50px 0 0 0">
    <div class="container">
      <app-clients-logo
        brand_class="col-lg-2 col-md-2 col-6 text-center py-4"
      ></app-clients-logo>
    </div>
    <!--end container-->
  </div>
  <div id="why-bleenco"></div>
  <!--end section-->
  <!-- Partners End -->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Feature Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">
            Bleenco is more than just a software, it's an AI studio
          </h4>
          <p class="text-muted para-desc mx-auto mb-0">
            We are on a mission to enable
            <span class="text-primary fw-bold">large enterprises</span> to
            automate
            <span class="text-primary fw-bold">analog processes</span> and
            upgrade
            <span class="text-primary fw-bold">legacy products</span> with the
            help of artificial intelligence.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-services
      [servicesData]="servicesData"
      service_class="features feature-primary mt-5"
    ></app-services>
  </div>
  <!--end container-->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="section-title">
          <h4 class="title mb-4">
            Achieve extraordinary returns with solutions that produce results
          </h4>
          <p class="text-muted para-desc">
            Begin working with Bleenco and explore the multitude of benefits
            that deliver tangible outcomes.
          </p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0">
              <span class="text-primary h5 me-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >TRL9 Technology, Ready for Immediate Integration
            </li>
            <li class="mb-0">
              <span class="text-primary h5 me-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >Large Selection of Ready-Made Solutions
            </li>
            <li class="mb-0">
              <span class="text-primary h5 me-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >Custom built solutions using
              <span class="text-primary">Bleenco Inside</span> technology stack
            </li>
            <li class="mb-0">
              <span class="text-primary h5 me-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >Superb Team With Diverse Expertise in Stream Processing
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6">
        <div class="row ms-lg-5" id="counter">
          <div class="col-md-6 col-12">
            <div class="row">
              <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div
                  class="card counter-box border-0 bg-light bg-gradient shadow text-center rounded"
                >
                  <div class="card-body py-5">
                    <h5 class="counter-head mb-0">
                      Trusted by <br />
                      <span class="text-primary fw-bold"
                        >Fortune
                        <span
                          class="counter-value"
                          [options]="option"
                          [countUp]="500"
                        ></span
                      ></span>
                      Companies
                    </h5>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card counter-box border-0 bg-primary bg-gradient shadow text-center rounded"
                >
                  <div class="card-body py-5">
                    <h2 class="text-white mb-0">
                      <span
                        class="counter-value"
                        [options]="option"
                        [countUp]="18"
                      ></span
                      >Gb+
                    </h2>
                    <h5 class="counter-head mb-0 text-white">
                      of Data Processed Every Second
                    </h5>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end Row-->
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="row pt-lg-4 mt-lg-4">
              <div class="col-12 mt-4 pt-2">
                <div
                  class="card counter-box border-0 bg-success bg-gradient shadow text-center rounded"
                >
                  <div class="card-body py-5">
                    <h5 class="counter-head mb-0 text-white">
                      Founded by the Creators of Pipelogic
                    </h5>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card counter-box border-0 bg-light bg-gradient shadow text-center rounded"
                >
                  <div class="card-body py-5">
                    <h2 class="mb-0">
                      <span
                        class="counter-value"
                        [options]="option"
                        [countUp]="8"
                      ></span>
                    </h2>
                    <h5 class="counter-head mb-0">
                      Technology <br />
                      Awards Won
                    </h5>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end Row-->
          </div>
          <!--end col-->
        </div>
        <!--end Row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <div id="case-studies"></div>
</section>
<!--end section-->
<!-- End feature -->

<!-- Start -->
<section class="section" style="padding-top: 0px">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">
            Find inspiration in real-world problem-solving case studies
          </h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Dive into our collection of real-world case studies. Whether you're
            in pursuit of
            <span class="text-primary fw-bold">product enhancement</span> or
            striving for
            <span class="text-primary fw-bold">process efficiency</span>,
            Bleenco's low-latency AI capabilities provide proven technology
            advantage to any organization.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <div class="row">
      <ul
        class="container-filter list-inline mb-0 filter-options text-center"
        id="filter"
      >
        <li
          class="list-inline-item categories-name border text-dark rounded ms-2"
          (click)="activeCategory('all')"
          [ngClass]="{ active: galleryFilter === 'all' }"
        >
          All
        </li>
        <li
          class="list-inline-item categories-name border text-dark rounded ms-2"
          (click)="activeCategory('product')"
          [ngClass]="{ active: galleryFilter === 'product' }"
        >
          Product
        </li>
        <li
          class="list-inline-item categories-name border text-dark rounded ms-2"
          (click)="activeCategory('process')"
          [ngClass]="{ active: galleryFilter === 'process' }"
        >
          Process
        </li>
      </ul>
    </div>
    <!--end row-->

    <div class="projects-wrapper mt-4 pt-2">
      <ngx-masonry [options]="myOptions" class="row">
        <div
          ngxMasonryItem
          class="col-md-6 col-12 spacing picture-item"
          *ngFor="let item of filterredImages"
        >
          <div
            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded"
          >
            <div class="card-body p-0">
              <a href="javascript:void(0)">
                <img
                  src="{{ item.image }}"
                  class="img-fluid"
                  alt="work-image"
                />
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <h5 class="mb-0 text-white title">{{ item.title }}</h5>
                  <h6 class="text-light tag mb-0">{{ item.text }}</h6>
                </div>
              </a>
            </div>
          </div>
        </div>
      </ngx-masonry>
    </div>
    <!--end row-->
  </div>
  <div id="get-booklet"></div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start CTA -->
<div class="container-fluid mt-60">
  <div class="rounded py-5 bg-booklet">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h2 class="fw-bold text-black mb-4">
              Future-proof your strategy for <br />digital transformation with
              AI
            </h2>
            <p class="para-desc text-black">
              Discover valuable insights, expert tips, and exclusive content in
              our latest booklet. Dive into a world of artificial intelligence
              and practical examples.
            </p>
            <ul class="list-unstyled text-muted">
              <li class="mb-0">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i></span
                >Over 30 case studies from real-world projects in one booklet
              </li>
              <li class="mb-0">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i></span
                >Covering operations in industrial environments and quality
                control of products
              </li>
              <li class="mb-0">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i></span
                >Transparent solutions and illustrative graphic materials
              </li>
            </ul>
            <div class="mt-4">
              <a routerLink="/contact" class="btn btn-primary mt-2 me-3">
                Get Booklet
              </a>
            </div>
            <br /><br /><br />
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </div>
</div>
<!--end container-->
<!-- End CTA -->

<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<!-- News Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Get started with Bleenco today!</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Curious how Bleenco can help you? Let's talk.
          </p>
          <div class="mt-4">
            <a routerLink="/contact" class="btn btn-primary mt-2 me-3">
              Contact Sales
            </a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- News End -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
