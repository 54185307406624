import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './core/components/index/index.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PageNewsroomComponent } from './core/components/page-newsroom/page-newsroom.component';
import { PageContactComponent } from './core/components/page-contact/page-contact.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageImpressumComponent } from './core/components/page-impressum/page-impressum.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'index' },
      { path: 'index', component: IndexComponent },
      { path: 'newsroom', component: PageNewsroomComponent },
      { path: 'contact', component: PageContactComponent },
      { path: 'privacy', component: PagePrivacyComponent },
      { path: 'impressum', component: PageImpressumComponent },
    ]
  },
  {
    path: '**', redirectTo: 'index'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "top",
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: "enabled", initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
