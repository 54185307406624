import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CountUpModule } from 'ngx-countup';
import { SharedModule } from "./shared/shared.module";
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { IndexComponent } from './core/components/index/index.component';
import { PageNewsroomComponent } from './core/components/page-newsroom/page-newsroom.component';
import { PageContactComponent } from './core/components/page-contact/page-contact.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageImpressumComponent } from './core/components/page-impressum/page-impressum.component';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MasterPageComponent,
    IndexComponent,
    PageNewsroomComponent,
    PageContactComponent,
    PagePrivacyComponent,
    PageImpressumComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CountUpModule,
    SharedModule,
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
