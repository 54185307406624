<!-- Navbar STart -->
<header
  id="topnav"
  class="defaultscroll sticky"
  (window:scroll)="windowScroll()"
  [class]="navClass"
  [ngClass]="{ 'tagline-height': sliderTopbar == true }"
>
  <div class="container">
    <!-- Logo container-->
    <div>
      <ul
        class="buy-button list-inline mb-0 d-none d-lg-block"
        [class]="navClass"
      >
        <li class="list-inline-item mb-0">
          <a routerLink="/contact" class="btn btn-primary me-3">
            Contact Sales
          </a>
        </li>
      </ul>

      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img
          src="assets/images/logo-dark.png"
          class="l-dark logo-light-mode"
          height="22"
          alt=""
        />
        <img
          src="assets/images/logo-light.png"
          class="logo-dark-mode"
          height="22"
          alt=""
        />
      </a>

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a
            class="navbar-toggle"
            id="isToggle"
            (click)="toggleMenu()"
            [ngClass]="{ open: isCondensed === true }"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>

      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img
          src="assets/images/logo-dark.png"
          class="l-dark"
          height="24"
          alt=""
        />
        <img
          src="assets/images/logo-light.png"
          class="l-light"
          height="24"
          alt=""
        />
      </a>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li>
          <a
            routerLink="/index"
            [ngxScrollTo]="'#why-bleenco'"
            class="nav-link-ref"
            >Why Bleenco</a
          >
        </li>
        <li>
          <a
            routerLink="/index"
            [ngxScrollTo]="'#case-studies'"
            class="nav-link-ref"
            >Case Studies</a
          >
        </li>
        <li>
          <a
            routerLink="/index"
            [ngxScrollTo]="'#get-booklet'"
            class="nav-link-ref"
            >Get Booklet</a
          >
        </li>
        <li class="d-lg-none">
          <a routerLink="/contact" class="nav-link-ref"> Contact Sales </a>
        </li>
        <li class="has-submenu parent-menu-item">
          <a href="javascript: void(0)" class="nav-link-ref">Company</a>
          <span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a class="nav-link-ref" routerLink="javascript: void(0)"
                >About Us
                <span class="badge rounded-pill bg-info me-1">SOON</span></a
              >
            </li>
            <li><a class="nav-link-ref" routerLink="/newsroom">Newsroom</a></li>
            <li>
              <a class="nav-link-ref" routerLink="javascript: void(0)"
                >Careers
                <span class="badge rounded-pill bg-info me-1">SOON</span></a
              >
            </li>
            <li>
              <a class="nav-link-ref" routerLink="javascript: void(0)"
                >Blog
                <span class="badge rounded-pill bg-info me-1">SOON</span></a
              >
            </li>
            <li><a class="nav-link-ref" routerLink="/contact">Contact</a></li>
          </ul>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
