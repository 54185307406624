import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

const url = 'https://widgets.sociablekit.com/linkedin-page-posts/widget.js';

@Component({
  selector: 'app-page-newsroom',
  templateUrl: './page-newsroom.component.html',
  styleUrls: ['./page-newsroom.component.css']
})

/**
 * Page Blog-Grid Component
 */
export class PageNewsroomComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;

  displayPosts = false;

  constructor(@Inject(DOCUMENT) private doc: Document) { }

  ngOnInit(): void {
    this.displayPosts = true;
    this.loadScript();
  }

  loadScript() {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    this.doc.getElementsByTagName('head')[0].appendChild(node);
  }
}
