<!-- Start Contact -->
<section class="section pt-5 mt-4" id="home">
  <div class="container mt-100 mt-60" style="width: 50%">
    <div class="row align-items-center">
      <div class="col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card custom-form rounded border-0 shadow p-4">
          <h4 class="mb-4">Contact Details</h4>
          <p class="text-muted">
            Begin collaborating with
            <span class="text-primary fw-bold">Bleenco</span> to enhance your
            sensor-based solutions with AI capabilities. Please don't hesitate
            to reach out using the form below or by sending an inquiry to
            <a href="mailto:hello@bleenco.com">hello&#64;bleenco.com</a>.
          </p>
          <br />
          <form [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label"
                    >Your Name <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="user"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      class="form-control ps-5"
                      placeholder="Name :"
                      formControlName="name"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label"
                    >Your Email <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="mail"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      class="form-control ps-5"
                      placeholder="Email :"
                      formControlName="email"
                    />
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Subject</label>
                  <div class="form-icon position-relative">
                    <i-feather
                      name="book"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      name="subject"
                      id="subject"
                      class="form-control ps-5"
                      placeholder="subject :"
                      formControlName="subject"
                    />
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label"
                    >Comments <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="message-circle"
                      class="fea icon-sm icons clearfix"
                    ></i-feather>
                    <textarea
                      name="comments"
                      id="comments"
                      rows="4"
                      class="form-control ps-5"
                      placeholder="Message :"
                      formControlName="comments"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    class="btn"
                    [ngClass]="{
                      'btn-primary': !mailSent || isLoading,
                      'btn-success': mailSent,
                      'btn-danger': error
                    }"
                    [disabled]="isLoading || !form.valid"
                  >
                    <span *ngIf="!mailSent && !isLoading && !error">
                      Send Message
                    </span>
                    <span *ngIf="mailSent && !isLoading">
                      Message sent successfully
                    </span>
                    <span *ngIf="isLoading">Sending message...</span>
                    <span *ngIf="error && !isLoading">Error sending email</span>
                  </button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->

      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
