import { Component, OnInit, Input } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  @Input() brand_class: string | undefined;
  /**
   * Clients Logo
   */
   clients_logo: client[] = [
    {
      image: "assets/images/client/basf.svg"
    },
    {
      image: "assets/images/client/constellium.svg"
    },
    {
      image: "assets/images/client/mb.svg"
    },
    {
      image: "assets/images/client/siemens.svg"
    },
    {
      image: "assets/images/client/poab.svg"
    },
    {
      image: "assets/images/client/abbvie.svg"
    }
  ]; 

  constructor() { }

  ngOnInit(): void {
  }

}
