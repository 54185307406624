<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100 d-print-none" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title">Privacy Policy</h4>
          <ul class="list-unstyled mt-4 mb-0">
            <li class="list-inline-item h6 date text-muted">
              <span class="text-dark">Last Revised :</span> 7th Nov, 2023
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Privacy</li>
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Overview:</h5>
            <p class="text-muted">
              This privacy policy will explain how our organization uses the
              personal data we collect from you when you use our website. We try
              to keep it short and simple – in case of any additional questions
              do not hesitate to reach out.
            </p>

            <h5 class="card-title">Topics addressed in this privacy policy:</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                What data do we collect?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                How do we collect your data?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                How will we use your data?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                How do we store your data?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Marketing
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                What are your data protection rights?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                What are cookies?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                How do we use cookies?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                What types of cookies do we use?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                How to manage your cookies?
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Privacy policies of other websites
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Changes to our privacy policy
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                How to contact us
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                How to contact the appropriate authorities
              </li>
            </ul>

            <h6 class="card-title">What data do we collect?</h6>
            <div class="text-muted">
              Our Company collects the following data:
            </div>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Personal identification information (Name, email address, phone
                number, etc.)
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Browser name and version, visited pages on our website, device
                name.
              </li>
            </ul>

            <h6 class="card-title">How do we collect your data?</h6>
            <div class="text-muted">
              You directly provide Our Company with most of the data we collect.
              We collect data and process data when you:
            </div>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Register online or place an order for any of our products or
                services.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Voluntarily complete a customer survey or provide feedback on
                any of our message boards or via email.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Use or view our website via your browser’s cookies.
              </li>
            </ul>

            <h6 class="card-title">How will we use your data?</h6>
            <div class="text-muted">
              Our Company collects your data so that we can:
            </div>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Process your order and manage your account.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Contact you to answer your inquiries and provide details about
                our product.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Email you with special offers on other products and services we
                think you might like.
              </li>
            </ul>
            <p class="text-muted">
              <b
                >Our Company will not share your data with other companies via
                3rd party services (e.g. Google Analytics).</b
              >
            </p>

            <p class="text-muted">
              When Our Company processes your order, it may send your data to,
              and also use the resulting information from, credit reference
              agencies to prevent fraudulent purchases.
            </p>

            <h6 class="card-title">How do we store your data?</h6>

            <p class="text-muted">
              Our Company securely stores your data in secured data centers
              located in Nürmberg and Frankfurt, Germany for the EU customers,
              and Reston, Virginia, United States for the rest of the world.
            </p>

            <p class="text-muted">
              Our Company will keep your browser data, email, name and other
              personal information for two years. Once this time period has
              expired, we will delete your data automatically by scheduled cron
              jobs on our server.
            </p>

            <h6 class="card-title">Marketing</h6>

            <p class="text-muted">
              Our Company would like to send you information about products and
              services of ours that we think you might like, as well as those of
              our partner companies. If you have agreed to receive marketing,
              you may always opt out at a later date. You have the right at any
              time to stop Our Company from contacting you for marketing
              purposes or giving your data to other members of the Our Company
              Group.
            </p>

            <p class="text-muted">
              If you no longer wish to be contacted for marketing purposes,
              please send us an email: dataprivacy&#64;bleenco.com
              <br /><br />
              Or write to us: Bleenco GmbH, Dachauer Strasse 44, 80335 München,
              Germany
            </p>

            <h6 class="card-title">What are your data protection rights?</h6>

            <div class="text-muted">
              Our Company would like to make sure you are fully aware of all of
              your data protection rights. Every user is entitled to the
              following:
            </div>

            <ul class="list-unstyled text-muted">
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                The right to access – You have the right to request Our Company
                for copies of your personal data. We may charge you a small fee
                for this service.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                The right to rectification – You have the right to request that
                Our Company correct any information you believe is inaccurate.
                You also have the right to request Our Company to complete the
                information you believe is incomplete.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                The right to erasure – You have the right to request that Our
                Company erase your personal data, under certain conditions.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                The right to restrict processing – You have the right to request
                that Our Company restrict the processing of your personal data,
                under certain conditions.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                The right to object to processing – You have the right to object
                to Our Company’s processing of your personal data, under certain
                conditions.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                The right to data portability – You have the right to request
                that Our Company transfer the data that we have collected to
                another organization, or directly to you, under certain
                conditions.
              </li>
            </ul>

            <p class="text-muted">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us at
              our email: dataprivacy&#64;bleenco.com
              <br /><br />
              Or write to us: Bleenco GmbH, Dachauer Strasse 44, 80335 München,
              Germany
            </p>

            <h6 class="card-title">What are cookies?</h6>

            <p class="text-muted">
              Cookies are text files placed on your computer to collect standard
              Internet log information and visitor behavior information. When
              you visit our websites, we may collect information from you
              automatically through cookies or similar technology. For further
              information, visit
              <a href="https://allaboutcookies.org/" target="_blank"
                >allaboutcookies.org</a
              >.
            </p>

            <h6 class="card-title">How do we use cookies?</h6>

            <div class="text-muted">
              Our Company uses cookies in a range of ways to improve your
              experience on our website, including:
            </div>

            <ul class="list-unstyled text-muted">
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Keeping you signed in.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Understanding how you use our website.
              </li>
            </ul>

            <h6 class="card-title">What types of cookies do we use??</h6>

            <div class="text-muted">
              There are a number of different types of cookies, however, our
              website uses:
            </div>

            <ul class="list-unstyled text-muted">
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Functionality – Our Company uses these cookies so that we
                recognize you on our website and remember your previously
                selected preferences. These could include what language you
                prefer and location you are in. A mix of first-party and
                third-party cookies are used.
              </li>
              <li>
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm me-2"
                ></i-feather>
                Advertising – Our Company uses these cookies to collect
                information about your visit to our website, the content you
                viewed, the links you followed and information about your
                browser, device, and your IP address. Our Company sometimes
                shares some limited aspects of this data with third parties for
                advertising purposes. We may also share online data collected
                through cookies with our advertising partners. This means that
                when you visit another website, you may be shown advertising
                based on your browsing patterns on our website.
              </li>
            </ul>

            <h6 class="card-title">How to manage cookies?</h6>

            <p class="text-muted">
              You can set your browser not to accept cookies, and the above
              website tells you how to remove cookies from your browser.
              However, in a few cases, some of our website features may not
              function as a result.
            </p>

            <h6 class="card-title">Privacy policies of other websites</h6>

            <p class="text-muted">
              Our Company website contains links to other websites. Our privacy
              policy applies only to our website, so if you click on a link to
              another website, you should read their privacy policy.
            </p>

            <h6 class="card-title">Changes to our privacy policy</h6>

            <p class="text-muted">
              Our Company keeps its privacy policy under regular review and
              places any updates on this web page. This privacy policy was last
              updated on 7th November 2023.
            </p>

            <p class="text-muted">
              If you have any questions about Our Company’s privacy policy, the
              data we hold on you, or you would like to exercise one of your
              data protection rights, please do not hesitate to contact us.
            </p>

            <p class="text-muted">
              Email us at: dataprivacy&#64;bleenco.com <br />
              Or write to us at: Bleenco GmbH, Dachauer Strasse 44, 80335
              München, Germany
            </p>

            <h6 class="card-title">
              How to contact the appropriate authority?
            </h6>

            <p class="text-muted">
              Should you wish to report a complaint or if you feel that Our
              Company has not addressed your concern in a satisfactory manner,
              you may contact the Information Commissioner’s Office.
            </p>

            <p class="text-muted">
              Email us at: dataprivacy&#64;bleenco.com <br />
              Or write to us at: Bleenco GmbH, Dachauer Strasse 44, 80335
              München, Germany
            </p>

            <a href="print()" class="btn btn-soft-primary d-print-none">
              Print
            </a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
