import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-impressum',
  templateUrl: './page-impressum.component.html',
  styleUrls: ['./page-impressum.component.css']
})

/**
 * PAge Privacy Component
 */
export class PageImpressumComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;

  get window(): Window | null {
    return this.doc.defaultView;
  }

  constructor(@Inject(DOCUMENT) private doc: Document) { }

  ngOnInit(): void { }

  print(): void {
    this.window?.print?.();
  }
}
